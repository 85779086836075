
export const texts = Object.freeze({
  'es': {
    'apples': {
      'varieties': {
        'amariega': 'Amariega',
        'antonona': 'Antonona',
        'arbeya': 'Arbeya',
        'beldredo': 'Beldredo',
        'blanquina': 'Blanca',
        'carrandona': 'Carrandona',
        'carrio': 'Carrió',
        'celso': 'Celso',
        'chatablanca': 'Chata blanca',
        'chataencarnada': 'Chata encarnada',
        'cladurina': 'Cladurina',
        'cladurinaamargoacida': 'Cladurina amargoácida',
        'clara': 'Clara',
        'collaina': 'Collaína',
        'collaos': 'Collaos',
        'coloradona': 'Colorada',
        'coloraamarga': 'Colorada amarga',
        'corchu': 'Corchu',
        'cristalina': 'Cristalina',
        'delariega': 'De la riega',
        'dura': 'Dura',
        'durondarroes': 'Durón d\'Arroes',
        'duronencarnado': 'Durón encarnado',
        'durcolora': 'Durcolorá',
        'duronadetresali': 'Durona de Tresali',
        'ernestina': 'Ernestina',
        'fresnosa': 'Fresnosa',
        'fuentes': 'Fuentes',
        'josefa': 'Josefa',
        'limonmontes': 'Limón Montés',
        'lin': 'Lin',
        'madiedo': 'Madiedo',
        'mariaelena': 'María Elena',
        'marinana': 'Mariñana',
        'martina': 'Martina',
        'meana': 'Meana',
        'miyeres': 'Miyeres',
        'montesdeflor': 'Montés de flor',
        'montesdelallamera': 'Montés de la llamera',
        'montoto': 'Montoto',
        'panquerina': 'Panquerina',
        'paraguas': 'Paraguas',
        'penarudes': 'Peñarudes',
        'perezosa': 'Perezosa',
        'perico': 'Perico',
        'perracabiella': 'Perracabiella',
        'perurico': 'Perurico',
        'peruricoprecoz': 'Perurico precoz',
        'picon': 'Picón',
        'prieta': 'Prieta',
        'raxao': 'Raxao',
        'raxarega': 'Raxarega',
        'raxilaacida': 'Raxila ácida',
        'raxiladulce': 'Raxila dulce',
        'raxilarayada': 'Raxila rayada',
        'raxinaamarga': 'Raxina amarga',
        'raxinaacida': 'Raxina ácida',
        'raxinadulce': 'Raxina dulce',
        'raxinamarelo': 'Raxina marelo',
        'raxonaacida': 'Raxona ácida',
        'raxonadulce': 'Raxona dulce',
        'regona': 'Regona',
        'reinetacaravia': 'Reineta Caravia',
        'reinetaencarnada': 'Reineta encarnada',
        'reinetapinta': 'Reineta pinta',
        'repinaldocaravia': 'Repinaldo Caravia',
        'repinaldodehueso': 'Repinaldo de hueso',
        'rosadona': 'Rosadona',
        'sanjusto': 'San Justo',
        'sanroquena': 'San Roqueña',
        'solarina': 'Solarina',
        'sucu': 'Sucu',
        'teorica': 'Teórica',
        'verdialona': 'Verdialona',
        'verdosa': 'Verdosa',
        'xuanina': 'Xuanina',
        'mezcla': 'Mezcla de variedades autorizadas'
      }
    },
    'events': {
      'typeEnum': {
        'newOwner': 'Nuevo dueño',
        'collected': 'Recolectada',
        'pressing': 'Prensado',
        'qualified': 'Calificado',
        'corked': 'Corchado',
        'distribution': 'Distribución',
        'bottleFermentation': 'Fermentación en botella',
        'depositFermentation': 'Fermentación en depósito',
        'filtered': 'Filtrado',
        'deguelle': 'Degüelle',
        'bottled': 'Envasado',
      }
    },
    'actors': {
      'typeEnum': {
        'issuer': 'Certificador',
        'winery': 'Llagar',
        'distributor': 'Distribuidor',
        'vendor': 'Establecimiento'
      }
    },
    'literals': {
      'timeline': 'Timeline',
      'originCouncil': 'Concejo Origen',
      'locations': 'Fincas',
      'varieties': 'Variedades',
      'asturianVarieties': 'Manzana Asturiana',
      'seeTrace': 'Ver Traza',
      'about': 'Acerca de',
      'numLabel': 'Nº Etiqueta',
      'label': 'Etiqueta',
      'you': 'Tú',
      'yourQuery': 'Comparte',
      'consumer': 'Consumidor',
      'blockchainInfo': 'Datos en Blockchain',
      'batch': "Lote",
      'tasting': "Nota de cata",
      "helpButton": "Ayuda",
      "doesntFound": "No encontrado",
    },
    'content': {
      'telosInfo': 'ha desarrollado esta aplicación utilizando tecnología Blockchain. Sirve para registrar y consultar la procedencia de la sidra en la red pública Telos.',
      'telosInfo1': 'CTIC Centro Tecnológico',
      'consejoInfo': 'es el Organismo Oficial acreditado para certificar que una sidra se califique como Denominación de Origen "Sidra de Asturias".',
      'consejoInfo1': 'El Consejo Regulador de la denominación de origen',
      'about1': 'El uso de esta aplicación de trazabilidad refuerza la confianza del consumidor sobre la calidad y el origen de la DOP Sidra de Asturias, proporcionando información adicional sobre el producto amparado.',
      'about2': 'Bajo la supervisión del Consejo Regulador, los productores de manzana y los llagares registran en Blockchain datos de su actividad diaria, con el fin de compartir una información veraz y segura acerca de los productos amparados con esta DOP.',
      'about3': 'La aplicación de consulta accede al registro en Blockchain y muestra de forma atractiva la procedencia de la manzana, sus variedades y el proceso de elaboración seguido hasta llegar al consumidor, una vez superados los estrictos controles desarrollados por el Consejo Regulador.',
      'aboutTitle': 'Trazabilidad de la DOP Sidra de Asturias en Blockchain:',
      'share': 'Si no tiene traza, no es una sidraza ',
      'show': 'Enseña este QR o comparte en redes sociales',
      'example': 'Introduce el número completo de la etiquetina para descubrir la traza de tu sidra, como en estos ejemplos: ',
      'example1': 'S5455353',
      'example2': 'R2084001',
      'aldeacero': 'Participan los llagares Menéndez, Castañon, Fran, Viuda de Angelón, JR, La Morena, Buznego, Quelo, El Gobernador y El Gaitero. Esta aplicación funciona gracias al proyecto Aldea 0, en el que se enmarca como un ejemplo de innovación asociada al territorio y la valorización de productos del sistema agroecológico local.',
      'step1Tour': 'Encuentra la etiquetina en tu botella.',
      'step2Tour': "O busca tú mismo el código.",
      "step3Tour": "Introduce el código identificativo.",
      "step4Tour": "Y pulsa el botonín para ver el origen.",
      "step5Tour": "Saca una foto y detecta automáticamente la traza."
    },
  },
  'en': {
    'apples': {
      'varieties': {
        'amariega': 'Amariega',
        'antonona': 'Antonona',
        'arbeya': 'Arbeya',
        'beldredo': 'Beldredo',
        'blanquina': 'White',
        'carrandona': 'Carrandona',
        'carrio': 'Carrió',
        'celso': 'Celso',
        'chatablanca': 'Chata blanca',
        'chataencarnada': 'Chata encarnada',
        'cladurina': 'Cladurina',
        'cladurinaamargoacida': 'Cladurina amargoácida',
        'clara': 'Clear',
        'collaina': 'Collaína',
        'collaos': 'Collaos',
        'coloradona': 'Red',
        'coloraamarga': 'Bitter Red',
        'corchu': 'Corchu',
        'cristalina': 'Crystalline',
        'delariega': 'De la riega',
        'dura': 'Tough',
        'durondarroes': 'Durón d\'Arroes',
        'duronencarnado': 'Durón encarnado',
        'durcolora': 'Durcolorá',
        'duronadetresali': 'Durona de Tresali',
        'ernestina': 'Ernestina',
        'fresnosa': 'Fresnosa',
        'fuentes': 'Fuentes',
        'josefa': 'Josefa',
        'limonmontes': 'Limón Montés',
        'lin': 'Lin',
        'madiedo': 'Madiedo',
        'mariaelena': 'María Elena',
        'marinana': 'Mariñana',
        'martina': 'Martina',
        'meana': 'Meana',
        'miyeres': 'Miyeres',
        'montesdeflor': 'Montés de flor',
        'montesdelallamera': 'Montés de la llamera',
        'montoto': 'Montoto',
        'panquerina': 'Panquerina',
        'paraguas': 'Umbrella',
        'penarudes': 'Peñarudes',
        'perezosa': 'Lazy',
        'perico': 'Perico',
        'perracabiella': 'Perracabiella',
        'perurico': 'Perurico',
        'peruricoprecoz': 'Perurico precoz',
        'picon': 'Picón',
        'prieta': 'Prieta',
        'raxao': 'Raxao',
        'raxarega': 'Raxarega',
        'raxilaacida': 'Raxila ácida',
        'raxiladulce': 'Raxila dulce',
        'raxilarayada': 'Raxila rayada',
        'raxinaamarga': 'Raxina amarga',
        'raxinaacida': 'Raxina ácida',
        'raxinadulce': 'Raxina dulce',
        'raxinamarelo': 'Raxina marelo',
        'raxonaacida': 'Raxona ácida',
        'raxonadulce': 'Raxona dulce',
        'regona': 'Regona',
        'reinetacaravia': 'Reineta Caravia',
        'reinetaencarnada': 'Reineta encarnada',
        'reinetapinta': 'Reineta pinta',
        'repinaldocaravia': 'Repinaldo Caravia',
        'repinaldodehueso': 'Repinaldo de hueso',
        'rosadona': 'Rosadona',
        'sanjusto': 'San Justo',
        'sanroquena': 'San Roqueña',
        'solarina': 'Solarina',
        'sucu': 'Sucu',
        'teorica': 'Teórica',
        'verdialona': 'Verdialona',
        'verdosa': 'Verdosa',
        'xuanina': 'Xuanina',
        'mezcla': 'Mixture of authorised varieties'
      }
    },
    'events': {
      'typeEnum': {
        'newOwner': 'New owner',
        'collected': 'Collected',
        'pressing': 'Pressing',
        'qualified': 'Qualified',
        'corked': 'Corked',
        'distribution': 'Distribution',
        'bottleFermentation': 'Bottle fermentation',
        'depositFermentation': 'Deposit fermentation',
        'filtered': 'Filtered',
        'deguelle': 'Yeast removal',
        'bottled': 'Bottled',
      }
    },
    'actors': {
      'typeEnum': {
        'issuer': 'Qualifier',
        'winery': 'Cidery',
        'distributor': 'Distributor',
        'vendor': 'Vendor'
      }
    },
    'literals': {
      'timeline': 'Timeline',
      'originCouncil': 'Origin Council',
      'locations': 'Locations',
      'varieties': 'Varieties',
      'asturianVarieties': 'Asturian Apple',
      'seeTrace': 'See Trace',
      'about': 'About',
      'numLabel': 'Nº Label',
      'label': 'Label',
      'you': 'You',
      'yourQuery': 'Share',
      'consumer': 'Consumer',
      'blockchainInfo': 'Blockchain Data',
      'batch': "Batch",
      'tasting': "Tasting Note",
      "helpButton": "Help",
      "doesntFound": "Not found",
    },
    'content': {
      'telosInfo': 'has developed this application using Blockchain technology. It is used to register and check the origin of the cider in the Telos public network',
      'telosInfo1': 'CTIC Technology Center',
      'consejoInfo': 'is the official organism established to certify that a cider qualifies as a Designation of Origin "Cider of Asturias".',
      'consejoInfo1': 'The Designation of Origin Regulatory Board',
      'about1': 'The use of this traceability application reinforces the confidence of the consumer in the quality and the origin of the PDO cider, providing additional information about the protected product.',
      'about2': 'Under the supervision of the regulatory council, the apples growers and cider makers registry in blockchain the data of their diary activity,in order to share truthful and safe information about products covered by this PDO.',
      'about3': 'The consultation application accesses the registration in Blockchain and shows in an attractive way the origin of the apple, its varieties and the elaboration process followed until reaching the consumer, once the strict controls developed by the Regulatory Council have been passed.',
      'aboutTitle': 'Traceability of the PDO Asturian Cider in Blockchain:',
      'share': 'It can\'t be a great cider if you can\'t check the provider ',
      'show': 'Show this QR or share in social networks',
      'example': 'Enter the complete number of the label to discover the trace of your cider, as in these examples: ',
      'example1': 'S5455353',
      'example2': 'R2084001',
      'aldeacero': 'The participating cideries are Menéndez, Castañon, Fran, Viuda de Angelón, JR, La Morena, Buznego, Quelo, El Gobernador and El Gaitero. This application is up and running thanks to the Aldea 0 project, in which it is framed as an example of innovation associated with the territory and the valorization of products from the local agroecological system.',
      'step1Tour': 'Find this tag in the bottle of your cider.',
      'step2Tour': "Or find the code yourself.",
      "step3Tour": "Write the code in the field",
      "step4Tour": "And click the button to see the origin.",
      "step5Tour": "Take a photo and automatically detect the trace."
    },
  },
  'ast': {
    'apples': {
      'varieties': {
        'amariega': 'Amariega',
        'antonona': 'Antonona',
        'arbeya': 'Arbeya',
        'beldredo': 'Beldredo',
        'blanquina': 'Blanquina',
        'carrandona': 'Carrandona',
        'carrio': 'Carrió',
        'celso': 'Celso',
        'chatablanca': 'Chata blanca',
        'chataencarnada': 'Chata encarnada',
        'cladurina': 'Cladurina',
        'cladurinaamargoacida': 'Cladurina amargoácida',
        'clara': 'Clara',
        'collaina': 'Collaína',
        'collaos': 'Collaos',
        'coloradona': 'Coloradona',
        'coloraamarga': 'Colorá amarga',
        'corchu': 'Corchu',
        'cristalina': 'Cristalina',
        'delariega': 'De la riega',
        'dura': 'Dura',
        'durondarroes': 'Durón d\'Arroes',
        'duronencarnado': 'Durón encarnado',
        'durcolora': 'Durcolorá',
        'duronadetresali': 'Durona de Tresali',
        'ernestina': 'Ernestina',
        'fresnosa': 'Fresnosa',
        'fuentes': 'Fuentes',
        'josefa': 'Josefa',
        'limonmontes': 'Limón Montés',
        'lin': 'Lin',
        'madiedo': 'Madiedo',
        'mariaelena': 'María Elena',
        'marinana': 'Mariñana',
        'martina': 'Martina',
        'meana': 'Meana',
        'miyeres': 'Miyeres',
        'montesdeflor': 'Montés de flor',
        'montesdelallamera': 'Montés de la llamera',
        'montoto': 'Montoto',
        'panquerina': 'Panquerina',
        'paraguas': 'Paraguas',
        'penarudes': 'Peñarudes',
        'perezosa': 'Perezosa',
        'perico': 'Perico',
        'perracabiella': 'Perracabiella',
        'perurico': 'Perurico',
        'peruricoprecoz': 'Perurico precoz',
        'picon': 'Picón',
        'prieta': 'Prieta',
        'raxao': 'Raxao',
        'raxarega': 'Raxarega',
        'raxilaacida': 'Raxila ácida',
        'raxiladulce': 'Raxila dulce',
        'raxilarayada': 'Raxila rayada',
        'raxinaamarga': 'Raxina amarga',
        'raxinaacida': 'Raxina ácida',
        'raxinadulce': 'Raxina dulce',
        'raxinamarelo': 'Raxina marelo',
        'raxonaacida': 'Raxona ácida',
        'raxonadulce': 'Raxona dulce',
        'regona': 'Regona',
        'reinetacaravia': 'Reineta Caravia',
        'reinetaencarnada': 'Reineta encarnada',
        'reinetapinta': 'Reineta pinta',
        'repinaldocaravia': 'Repinaldo Caravia',
        'repinaldodehueso': 'Repinaldo de hueso',
        'rosadona': 'Rosadona',
        'sanjusto': 'San Justo',
        'sanroquena': 'San Roqueña',
        'solarina': 'Solarina',
        'sucu': 'Sucu',
        'teorica': 'Teórica',
        'verdialona': 'Verdialona',
        'verdosa': 'Verdosa',
        'xuanina': 'Xuanina',
        'mezcla': 'Amiestu de variedaes autorizaes'
      }
    },
    'events': {
      'typeEnum': {
        'newOwner': 'Nuevu dueñu',
        'collected': 'Pañada',
        'pressing': 'Mallada',
        'qualified': 'Calificada',
        'corked': 'Corchá',
        'distribution': 'Repartu',

        'bottleFermentation': 'Fermentación en botella',
        'depositFermentation': 'Fermentación en depósitu',
        'filtered': 'Filtrau',
        'deguelle': 'Degüelle',
        'bottled': 'Embotellau',
      }
    },
    'actors': {
      'typeEnum': {
        'issuer': 'Certificador',
        'winery': 'Llagar',
        'distributor': 'Repartidor',
        'vendor': 'Establecimientu'
      }
    },
    'literals': {
      'timeline': 'De onde vien',
      'originCouncil': 'Conceyu d\'Orixen',
      'locations': 'Finques',
      'varieties': 'Variedaes',
      'asturianVarieties': 'Mazana Asturiana',
      'seeTrace': 'Ver Traza',
      'about': 'Que Ye Esto',
      'numLabel': 'Nº Etiqueáu',
      'label': 'Etiqueáu',
      'you': 'Tú',
      'yourQuery': 'Comparte',
      'consumer': 'Consumidor',
      'blockchainInfo': 'Datos en Blockchain',
      'batch': "Lote",
      'tasting': "Nota de cata",
      "helpButton": "Ayuda",
      "doesntFound": "Non atopáu",
    },
    'content': {
      'telosInfo': 'desenvolvió esta aplicación utilizando tecnoloxía Blockchain. Sirve para rexistrar y consultar la procedencia de la sidra na rede pública Telos.',
      'telosInfo1': 'CTIC Centru Tecnolóxicu',
      'consejoInfo': 'ye l\'Organismu Oficial  acreditáu para certificar qu\'una sidra pueda califícase como Denominación de Origen "Sidra d\'Asturies."',
      'consejoInfo1': 'El Conseyu Regulador de la denominación d\'orixen ',
      'about1': 'L\'usu d\'esta aplicación de trazabilidad refuerza l\'enfotu del consumidor sobro la calidá y l\'orixe de la DOP Sidra d\'Asturies, apurriendo información adicional sobro\'l productu amparáu.',
      'about2': 'So la supervisión del Conseyu Regulador, el productores de mazana y los llagares rexistren en Blockchain datos de la so actividá diaria, col fin de compartir una información veraz y segura sobro los productos amparaos con esta DOP.',
      'about3': 'L\'aplicación de consulta apuerta al rexistru en Blockchain y amuesa de forma curiosa la procedencia de la mazana, les sos variedaes y el procesu d\'ellaboración siguíu hasta llegar al consumidor, una vegada superaos los estrictos controles desenvueltos pol Conseyu Regulador.',
      'aboutTitle': 'Trazabilidad de la DOP Sidra d\'Asturies en Blockchain:',
      'share': 'Si nun tien traza, nun ye una sidraza ',
      'show': 'Enseña esti QR o comparte en redes sociales',
      'example': "Introduz el númberu completu de la etiquetina p'afayar la traza de la to sidra, como nestos exemplos:",
      'example1': 'S5455353',
      'example2': 'R2084001',
      'aldeacero': 'Participen los llagares Menéndez, Castañon, Fran, Viuda de Angelón, JR, La Morena, Buznego, Quelo, El Gobernador y El Gaitero. Esta aplicación funciona gracies al proyeutu Aldea 0, nel que se enmarca comu\'n exemplu d\'innovación asociá al territoriu y la valorización de productos del sistema agroecolóxicu llocal.',
      'step1Tour': 'Atopa la etiquetina na to botella.',
      'step2Tour': "O busca tu mesmu'l códigu.",
      "step3Tour": "Introduz el códigu identificativu.",
      "step4Tour": "Y pulsia el botonín pa ver l'orixe.",
      "step5Tour": "Saca una semeya y detecta automáticamente la traza.",
    },
  }
})

// https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key

export function i18n(lang, path) {
  let obj = texts[lang];
  let separator = '.';

  var properties = Array.isArray(path) ? path : path.split(separator)
  return properties.reduce((prev, curr) => prev && prev[curr], obj)
}